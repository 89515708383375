<template>
  <v-card class="ma-3">
    <v-toolbar>
      <v-toolbar-title>Library Archives</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on">
            Additional Links
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="{ link, title, subtitle } in links" :key="link" :href="link" target="_blank">
            <v-list-item-content>
              <v-list-item-title>{{ title }}</v-list-item-title>
              <v-list-item-subtitle v-if="subtitle">{{ subtitle }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-if="isAdmin" outlined @click="addEntry">
        <v-icon left>fal fa-plus</v-icon>
        Add Entry
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col>
          <v-select v-model="category" :items="categories" label="Category" outlined clearable hide-details></v-select>
        </v-col>
        <v-col>
          <v-text-field v-model="search" :label="searchLabel" outlined clearable hide-details>
            <template v-slot:append>
              <v-menu left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon style="margin-top:-10px">
                    <v-icon small>fal fa-exchange</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="searchField = 'title'">
                    <v-list-item-title>Title and Author Search</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="searchField = 'text'">
                    <v-list-item-title>Full Text Search</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-menu ref="dateMenuRef" v-model="dateMenu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-on="on" v-bind="attrs" v-model="dateDisplay" label="Date Search" prepend-inner-icon="fal fa-calendar-day" readonly outlined clearable hide-details></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable range>
              <v-dialog v-model="dateHelpDialog" width="400">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" text>Help</v-btn>
                </template>
                <v-card>
                  <v-card-title>Date Search Help</v-card-title>
                  <v-card-text>
                    <p>You can choose one date or two dates.</p>
                    <ol>
                      <li>When you choose one date, it will set the minimum date and will return everything on or after that date.</li>
                      <li>When you choose a second date, the dates will specify a range and will return everything on or after the lower of the two dates up to (but not including) the higher date.</li>
                    </ol>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text @click="dateHelpDialog = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateMenu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.dateMenuRef.save(date)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table :items="entries" :headers="headers" :loading="loading" :server-items-length="entryCount" :options.sync="pagination" @update:options="loadResults" @dblclick:row="(e, { item }) => downloadItem(item)">
      <template v-slot:item.thumbnail="{ item }">
        <v-card elevation="1" class="pa-0" style="width:48px;">
          <v-img :src="item.thumbnail || '/img/icons/msapplication-icon-144x144.png'" contain style="width:48px;height:64px" />
        </v-card>
      </template>
      <template v-slot:item.date="{ item }">{{ stringFormatDate(item.date, true, true) }}</template>
      <template v-slot:item.format="{ item }">
        <v-tooltip v-if="item.format === 'pdf'" top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">fal fa-file-pdf</v-icon>
          </template>
          <span>Format: PDF</span>
        </v-tooltip>
        <v-tooltip v-else-if="item.format === 'mp3'" top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">fal fa-volume</v-icon>
          </template>
          <span>Format: MP3</span>
        </v-tooltip>
        <span v-else>{{ item.format }}</span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-menu v-if="isAdmin">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon>fal fa-ellipsis-v</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item :disabled="!('fileLink' in item)" @click="downloadItem(item)">
              <v-list-item-avatar>
                <v-icon>fal fa-download</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Download File</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isAdmin" @click="editItem(item)">
              <v-list-item-avatar>
                <v-icon>fal fa-pencil</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Edit Entry</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn v-else-if="'fileLink' in item" @click="downloadItem(item)">
          <v-icon>fal fa-download</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <edit-dialog ref="editDialog" :id="selectedId" @updated="updateRecord"></edit-dialog>
    <v-dialog v-model="downloading" width="400" persistent>
      <v-card>
        <v-card-title>Downloading...</v-card-title>
        <v-card-text>Downloading the file. This should only take a few seconds...</v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'
import axios from 'axios'

export default {
  components: {
    EditDialog: () => import('@/components/library/archives/entryDialog')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isAdmin = computed(() => user.value.roles && user.value.roles.filter((role) => role === 'Library' || role === 'Technology Services').length > 0)
    const category = computed({
      get: () => root.$store.state.library.archives.search.category,
      set: (category) => root.$store.commit('library/setArchiveSearch', { category })
    })
    const search = computed({
      get: () => root.$store.state.library.archives.search.text,
      set: (text) => root.$store.commit('library/setArchiveSearch', { text })
    })
    const searchField = computed({
      get: () => root.$store.state.library.archives.search.field,
      set: (field) => root.$store.commit('library/setArchiveSearch', { field })
    })
    const searchLabel = computed(() => {
      return searchField.value === 'title' ? 'Title and Author Search' : 'Full Text Search'
    })
    const date = computed({
      get: () => root.$store.state.library.archives.search.date,
      set: (date) => root.$store.commit('library/setArchiveSearch', { date })
    })
    const dateDisplay = computed(() => {
      let str = ''
      if (date.value.length > 0) {
        str += stringFormatDate(date.value[0] + ' 08:00:00', true, true).substring(5)
      }
      if (date.value.length > 1) {
        str += ' - ' + stringFormatDate(date.value[1] + ' 08:00:00', true, true).substring(5)
      }
      return str
    })
    const dateMenu = ref(false)
    const dateMenuRef = ref(null)
    const dateHelpDialog = ref(false)
    const categories = computed(() => root.$store.state.library.archives.categories.filter(({ active, loginRequired }) => active && (!loginRequired || user.value.isLoggedIn)).map(({ name, label }) => { return { value: name, text: label } }))

    onMounted(() => {
      if (categories.value.length === 0) {
        root.$store.dispatch('library/loadArchiveCategories')
      }
      loadResults()
    })

    const loading = ref(false)
    const entries = ref([])
    const entryCount = ref(0)
    const headers = computed(() => {
      const arr = [{ text: 'Thumbnail', value: 'thumbnail' }]
      if (category.value == null || category.value === '') arr.push({ text: 'Category', value: 'category' })
      arr.push({ text: 'Sub-Category', value: 'subcategory' })
      arr.push({ text: 'Title', value: 'title' })
      arr.push({ text: 'Date', value: 'date' })
      arr.push({ text: 'Description', value: 'description' })
      arr.push({ text: 'Author', value: 'author' })
      arr.push({ text: 'Format', value: 'format' })
      if (isAdmin.value) arr.push({ text: 'Actions', value: 'action' })
      else arr.push({ text: 'Download', value: 'action' })
      return arr
    })
    const pagination = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['date'],
      sortDesc: [true]
    })
    function loadResults (options) {
      loading.value = true
      entries.value = []
      if (options) {
        pagination.value = options
      }
      const query = {}
      if (!('username' in user.value)) {
        // User is not logged in
        query.permission = 'Public'
      }
      if (category.value != null && category.value !== '') query.category = category.value
      if (search.value != null) {
        if (searchField.value === 'text') query.$text = { $search: search.value }
        else query.$or = [{ description: { $regex: search.value, $options: 'i' } }, { title: { $regex: search.value, $options: 'i' } }, { author: { $regex: search.value, $options: 'i' } }]
      }
      if (date.value.length > 0) {
        const [start, end] = date.value
        if (start && end) {
          if (start > end) query.date = { $gte: new Date(end), $lte: new Date(start) }
          else query.date = { $gte: new Date(start), $lte: new Date(end) }
        } else if (start) {
          query.date = { $gte: new Date(start) }
        } else {
          query.date = { $lte: new Date(end) }
        }
      }
      const { page, itemsPerPage, sortBy, sortDesc } = pagination.value
      query.$limit = itemsPerPage
      query.$skip = (page - 1) * itemsPerPage
      query.$sort = {}
      for (let i = 0; i < sortBy.length; i++) {
        query.$sort[sortBy[i]] = sortDesc[i] ? -1 : 1
      }
      root.$feathers.service('library/archive/entry').find({ query }).then(({ total, data }) => {
        if (total > 0 && data.length === 0) {
          pagination.value.page = 1
          loadResults()
        }
        entryCount.value = total
        entries.value = data
        loading.value = false
      })
    }
    watch([category, date], () => {
      loadResults()
    })
    const searchTimeout = ref(null)
    watch(search, () => {
      clearTimeout(searchTimeout.value)
      if (search.value == null) {
        loadResults()
      } else if (searchField.value === 'title') {
        searchTimeout.value = setTimeout(() => { loadResults() }, 300)
      } else {
        searchTimeout.value = setTimeout(() => { loadResults() }, 700)
      }
    })

    function viewItem (item) {
      console.log(item)
    }

    const downloading = ref(false)
    function downloadItem (item) {
      console.log(item)
      if (item.fileLink) {
        downloading.value = true
        const basePath = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH.replace('/socket.io', '')
        axios({
          method: 'get',
          url: basePath + '/library/archive/drive-file/' + item.fileLink,
          responseType: 'blob',
          headers: { 'Content-Type': 'multipart/form-data' }
        }).catch((e) => {
          let snackbar = { active: true, color: 'error', timeout: 6000, text: 'Error downloading file: ' + e }
          root.$store.dispatch('main/snackbar', snackbar)
        }).then((response) => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = item.title.replaceAll(' ', '_')
          switch (response.headers['content-type']) {
            case 'application/pdf':
              link.download += '.pdf'
              break
            case 'txt':
              link.download += '.txt'
              break
            default:
              link.download += '.mp3'
              break
          }
          link.click()
          downloading.value = false
        })
      }
    }

    const editDialog = ref(null)
    const selectedId = ref(null)

    function editItem (item) {
      if (isAdmin.value) {
        selectedId.value = item._id
        editDialog.value.activate()
      }
    }

    function addEntry () {
      selectedId.value = ''
      editDialog.value.activate()
    }

    function updateRecord (obj) {
      if (selectedId.value !== '') {
        for (let i = 0; i < entries.value.length; i++) {
          if (entries.value[i]._id === selectedId.value) {
            entries.value.splice(i, 1, { ...entries.value[i], ...obj })
            break
          }
        }
      }
    }

    const links = ref([
      { link: 'https://soundcloud.com/covenantcollege/sets', title: 'Covenant College Chapel on SoundCloud', subtitle: '2012 - 2020' },
      { link: 'https://www.youtube.com/user/CovenantCollege', title: 'Covenant College on YouTube' },
      { link: 'https://portal.covenant.edu/student/sip', title: 'SIP and Capstone Archive', subtitle: 'Covenant Login Required' },
      { link: 'https://www.pcahistory.org/pca/ga/index.html', title: 'Minutes of the General Assembly of the PCA', subtitle: '1973 - Present' },
      { link: 'https://archive.covenant.edu/news/archive', title: 'Covenant.edu Website Archives', subtitle: '2008 - 2020' }
    ])

    return {
      user,
      isAdmin,
      category,
      search,
      searchField,
      searchLabel,
      date,
      dateDisplay,
      dateMenu,
      dateMenuRef,
      dateHelpDialog,
      categories,
      loading,
      entries,
      entryCount,
      headers,
      pagination,
      loadResults,
      viewItem,
      downloading,
      downloadItem,
      editDialog,
      selectedId,
      editItem,
      addEntry,
      updateRecord,
      links,
      stringFormatDate
    }
  }
}
</script>
