var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-3"},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Library Archives")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_vm._v(" Additional Links "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.links),function(ref){
var link = ref.link;
var title = ref.title;
var subtitle = ref.subtitle;
return _c('v-list-item',{key:link,attrs:{"href":link,"target":"_blank"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(title))]),(subtitle)?_c('v-list-item-subtitle',[_vm._v(_vm._s(subtitle))]):_vm._e()],1)],1)}),1)],1),(_vm.isAdmin)?_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.addEntry}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus")]),_vm._v(" Add Entry ")],1):_vm._e()],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.categories,"label":"Category","outlined":"","clearable":"","hide-details":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.searchLabel,"outlined":"","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-top":"-10px"},attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-exchange")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){_vm.searchField = 'title'}}},[_c('v-list-item-title',[_vm._v("Title and Author Search")])],1),_c('v-list-item',{on:{"click":function($event){_vm.searchField = 'text'}}},[_c('v-list-item-title',[_vm._v("Full Text Search")])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-menu',{ref:"dateMenuRef",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date Search","prepend-inner-icon":"fal fa-calendar-day","readonly":"","outlined":"","clearable":"","hide-details":""},model:{value:(_vm.dateDisplay),callback:function ($$v) {_vm.dateDisplay=$$v},expression:"dateDisplay"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v("Help")])]}}]),model:{value:(_vm.dateHelpDialog),callback:function ($$v) {_vm.dateHelpDialog=$$v},expression:"dateHelpDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Date Search Help")]),_c('v-card-text',[_c('p',[_vm._v("You can choose one date or two dates.")]),_c('ol',[_c('li',[_vm._v("When you choose one date, it will set the minimum date and will return everything on or after that date.")]),_c('li',[_vm._v("When you choose a second date, the dates will specify a range and will return everything on or after the lower of the two dates up to (but not including) the higher date.")])])]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dateHelpDialog = false}}},[_vm._v("Close")])],1)],1)],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateMenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dateMenuRef.save(_vm.date)}}},[_vm._v("OK")])],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.entries,"headers":_vm.headers,"loading":_vm.loading,"server-items-length":_vm.entryCount,"options":_vm.pagination},on:{"update:options":[function($event){_vm.pagination=$event},_vm.loadResults],"dblclick:row":function (e, ref) {
	var item = ref.item;

	return _vm.downloadItem(item);
}},scopedSlots:_vm._u([{key:"item.thumbnail",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"pa-0",staticStyle:{"width":"48px"},attrs:{"elevation":"1"}},[_c('v-img',{staticStyle:{"width":"48px","height":"64px"},attrs:{"src":item.thumbnail || '/img/icons/msapplication-icon-144x144.png',"contain":""}})],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.stringFormatDate(item.date, true, true)))]}},{key:"item.format",fn:function(ref){
var item = ref.item;
return [(item.format === 'pdf')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("fal fa-file-pdf")])]}}],null,true)},[_c('span',[_vm._v("Format: PDF")])]):(item.format === 'mp3')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("fal fa-volume")])]}}],null,true)},[_c('span',[_vm._v("Format: MP3")])]):_c('span',[_vm._v(_vm._s(item.format))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.isAdmin)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-ellipsis-v")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"disabled":!('fileLink' in item)},on:{"click":function($event){return _vm.downloadItem(item)}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("fal fa-download")])],1),_c('v-list-item-title',[_vm._v("Download File")])],1),(_vm.isAdmin)?_c('v-list-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("fal fa-pencil")])],1),_c('v-list-item-title',[_vm._v("Edit Entry")])],1):_vm._e()],1)],1):('fileLink' in item)?_c('v-btn',{on:{"click":function($event){return _vm.downloadItem(item)}}},[_c('v-icon',[_vm._v("fal fa-download")])],1):_vm._e()]}}])}),_c('edit-dialog',{ref:"editDialog",attrs:{"id":_vm.selectedId},on:{"updated":_vm.updateRecord}}),_c('v-dialog',{attrs:{"width":"400","persistent":""},model:{value:(_vm.downloading),callback:function ($$v) {_vm.downloading=$$v},expression:"downloading"}},[_c('v-card',[_c('v-card-title',[_vm._v("Downloading...")]),_c('v-card-text',[_vm._v("Downloading the file. This should only take a few seconds...")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }